import React, { useState } from 'react';

import { Container, Grid } from '@material-ui/core';

import { RoadMap } from './RoadMap';
import { SignUp } from './SignUp';
import { Payment } from './Payment';
import { CTAWelcome } from './CTAWelcome';
import { CTASignUp } from './CTASignUp';
import { CTASelectOffer } from './CTASelectOffer';
import './offerPaid.css';


function OfferPaidComponent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [product, setProduct] = useState('');

  const handleSignUp = (uid, email, name) => {
    setUid(uid);
    setEmail(email);
    setName(name);
    setCurrentStep(2);
  }

  const handleProduct = (product) => {
    setProduct(product);
  }

  const handlePayment = () => {
    setCurrentStep(3);
  }

  return (<>
    <Container
      component="main"
      maxWidth="xl"
      style={{ paddingTop: '50px' }}
    >
      <Grid container>
        {currentStep == 3 && (<>
          <Grid
            item
            xs={false}
            md={3}
            xl={4}
          >
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
          >
            <CTAWelcome email={email} />
          </Grid>
          <Grid
            item
            xs={false}
            md={3}
            xl={4}
          >
          </Grid>
        </>)}
        {currentStep != 3 && (<>
          <Grid
            item
            xs={12}
            md={6}
            className="offer-section"
          >
            {currentStep == 1 && (<CTASignUp />)}
            {currentStep == 2 && (<CTASelectOffer productId={product} />)}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="offer-section"
          >
            <RoadMap currentStep={currentStep} />
            {currentStep == 1 && (<SignUp finishSignUp={handleSignUp} />)}
            {currentStep == 2 && (<Payment uid={uid} name={name} email={email} handleProduct={handleProduct} finishPayment={handlePayment} />)}
          </Grid>
        </>)}
      </Grid>
    </Container>
  </>);
}

export const OfferPaid = OfferPaidComponent;