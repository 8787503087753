import React from 'react';

import { Grid, Stepper, Step, StepLabel } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import './offerPaid.css';
import { listOfSubscriptions } from '../../common/envConfig';

function CTASelectOfferComponent({ productId }) {

  return (<>
    <Stepper activeStep={2} className="cta-mobile-stepper">
      <Step key={1} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false}>
        <StepLabel></StepLabel>
      </Step>
      <Step key={2} completed={false} className="inactive">
        <StepLabel></StepLabel>
      </Step>
    </Stepper>
    <div className="cta-caption">
      Try 3d golf
      <span>10% off</span>
    </div>
    {/*<div className="cta-subcaption">
      Then ${productId === listOfSubscriptions.STUDENT_LITE_ANNUALLY ? '4.58' : '15.99'}/month after your trial. Cancel anytime.
    </div>*/}
    <div className="cta-invitational-benefits-wrapper">
      <ul className="cta-invitational-benefits">
        <li><CheckIcon className="cta-benefit-mark" /> Access to 100+ practice guides</li>
        <li><CheckIcon className="cta-benefit-mark" /> Identify areas of improvement with 3D analysis</li>
        <li><CheckIcon className="cta-benefit-mark" /> Track progress with swing assessments</li>
      </ul>
      {window.location.href.includes("/invitational") && <div className="cta-invitational">
        <img
          alt="Invitational"
          src="/images/offer/invitational.png"
          width={350}
        />
      </div>}
    </div>
    <div className="cta-total">
      <Grid container spacing={2} className="cta-total-today">
        <Grid
          item
          xs={6}
        >
          Billed Today
        </Grid>
        <Grid
          item
          xs={6}
          className="cta-total-sum"
        >
          ${productId === listOfSubscriptions.STUDENT_LITE_ANNUALLY ? '99 annually' : '14.39 monthly'}
        </Grid>
      </Grid>
      {/*<Grid container spacing={2} className="cta-total-after">
        <Grid
          item
          xs={6}
        >
          Billed after trial
        </Grid>
        <Grid
          item
          xs={6}
          className="cta-total-sum"
        >
          ${productId === listOfSubscriptions.STUDENT_LITE_ANNUALLY ? '55 annually' : '15.99 monthly'}
        </Grid>
      </Grid>*/}
    </div>
  </>);
}

export const CTASelectOffer = CTASelectOfferComponent;