import React, { useCallback, useEffect, useState } from "react";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import s from "./style.module.css";
import { InputV2 } from "../../../../components/redesign-components/InputV2/InputV2";
import { axiosWithToken, functionBaseUrl } from "../../../../common/firebase";

export const TableRowTag = ({
  values,
  options,
  videoId,
  onAddTagToVideo,
  onTagAdd,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [optionsState, setOptionsState] = useState(options);

  useEffect(() => {
    setOptionsState(options);
  }, [options]);

  useEffect(() => {
    if (searchValue) {
      function fuzzySearch(input, array) {
        const inputLower = input.toLowerCase();

        return array.filter((item) => {
          let itemLower = `${item}`.toLowerCase();
          let searchIndex = 0;

          for (let i = 0; i < itemLower.length; i++) {
            if (itemLower[i] === inputLower[searchIndex]) {
              searchIndex++;
            }
            if (searchIndex === inputLower.length) {
              return true;
            }
          }

          return false;
        });
      }

      setOptionsState(fuzzySearch(searchValue, options));
    } else {
      setOptionsState(options);
    }
  }, [searchValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddTag = useCallback(async () => {
    const trimmedSearchValue = searchValue.trim();
    await onTagAdd(trimmedSearchValue);
  }, [searchValue]);

  const handleOptionClick = async (tag, type) => {
    await axiosWithToken(functionBaseUrl + "/api/videos/tag/update", {
      method: "PATCH",
      data: {
        videoId,
        tag,
        type,
      },
    });

    await onAddTagToVideo();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={s.container}>
      <div className={`${s.selectWrapper}`}>
        <div className={s.selectValue}>
          {values.length ? (
            <>
              <span className={s.firstTag}>
                {values.length ? values[0] : ""}
              </span>

              {values.length > 1 ? (
                <span className={s.otherTags}>+{values.length - 1}</span>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <span onClick={handleClick} className={s.addTagButton}>
            <AddIcon />
          </span>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            minWidth: "200px",
            marginTop: "10px",
            borderRadius: "10px",
          },
        }}
      >
        <div className={s.options}>
          <div className={s.optionsLabel}>Tags</div>

          <div className={s.searchContainer}>
            <InputV2
              onChange={(name, value) => {
                const trimmedValue = value.replace(/^\s+/, '');
                setSearchValue(trimmedValue);
              }}
              name="search"
              value={searchValue}
              placeholder="Search"
              disabled={false}
            />
          </div>

          {optionsState.map((el) => (
            <div
              style={{ display: "flex", gap: "5px" }}
              className={s.option}
              key={el.value}
              onClick={() =>
                handleOptionClick(
                  el,
                  values.length && values.includes(el) ? "remove" : "add"
                )
              }
            >
              {values.length && values.includes(el) ? (
                <CheckBoxOutlinedIcon style={{ color: "#4009A5" }} />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon
                  style={{ color: "#4009A5" }}
                />
              )}
              <div>{el}</div>
            </div>
          ))}
        </div>

        {optionsState.length === 0 ? (
          <div className={s.createNewTagButton} onClick={handleAddTag}>
            <AddIcon /> <span>Create new</span>
          </div>
        ) : (
          ""
        )}
      </Popover>
    </div>
  );
};
