import React, { useRef, useState, useEffect } from "react";

import s from "./styles.module.css";

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const HorizontalScrollWrapper = ({ children, width = "300px" }) => {
  const containerRef = useRef(null);
  // const [canScrollLeft, setCanScrollLeft] = useState(true);
  // const [canScrollRight, setCanScrollRight] = useState(true);

  // useEffect(() => {
  //   checkScrollPosition();
  //   const currentRef = containerRef.current;
  //   if (currentRef) {
  //     currentRef.addEventListener("scroll", checkScrollPosition);
  //   }
  //   return () => {
  //     if (currentRef) {
  //       currentRef.removeEventListener("scroll", checkScrollPosition);
  //     }
  //   };
  // }, []);

  // const checkScrollPosition = () => {
  //   if (containerRef.current) {
  //     const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
  //     setCanScrollLeft(scrollLeft > 0);
  //     setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  //   }
  // };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" }); // Scroll left by 100 pixels
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" }); // Scroll right by 100 pixels
    }
  };

  return (
    <div className={s.scrollContainer} style={{ width }}>
      {/* {canScrollLeft && (
        <span onClick={scrollLeft} className={s.scrollButton}>
          <NavigateBeforeIcon style={{ fontSize: "18px" }} />
        </span>
      )} */}
      <span onClick={scrollLeft} className={s.scrollButton}>
        <NavigateBeforeIcon style={{ fontSize: "18px" }} />
      </span>
      <div className={s.overflowContainer} ref={containerRef}>
        {children}
      </div>

      {/* {canScrollRight && (
        <span onClick={scrollRight} className={s.scrollButton}>
          <NavigateNextIcon style={{ fontSize: "18px" }} />
        </span>
      )} */}
      <span onClick={scrollRight} className={s.scrollButton}>
        <NavigateNextIcon style={{ fontSize: "18px" }} />
      </span>
    </div>
  );
};
