import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TocIcon from "@material-ui/icons/Toc";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Storefront from "@material-ui/icons/Storefront";
import NotificationsIcon from "@material-ui/icons/Notifications";
import BarChartIcon from "@material-ui/icons/BarChart";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import BusinessIcon from "@material-ui/icons/Business";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DevicesIcon from "@material-ui/icons/Devices";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AssistantIcon from "@material-ui/icons/Assistant";
import ComputerIcon from "@material-ui/icons/Computer";
import { Profile, SidebarNav } from "./components";

import firebase, {
  axiosWithToken,
  functionBaseUrl,
} from "../../../../common/firebase";
import Swal from "sweetalert2";
import { ENVIRONMENT } from "../../../../common/envConfig";

const useStyles = (theme) => ({
  drawer: {
    width: "260px",
    [theme.breakpoints.up("lg")]: {
      //marginTop: 64,
      height: "100%",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
    flex: 1,
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      userRole: "",
      permission: "",
      subData: {},
      subscriptionType: "",
      isStaffEnterprise: true,
      remoteSessionCheck: false,
      exceptionList: [
        "paulpark89@gmail.com",
        "mike@fiveirongolf.com",
        "tyrus.poxson@fiveirongolf.com",
        "jeremy.hayward@fiveirongolf.com",
        "demo1.britishmasters@gmail.com",
      ],
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnap = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (!userSnap.exists) {
          console.log("No matching documents for UID: " + user.uid);
          this.setState({
            error: "The signed in user does not exist",
          });
        }
        const userDoc = userSnap.data();
        if (userDoc) {
          this.setState({
            userRole: userDoc?.role,
            email: userDoc?.email,
            subscriptionType: userDoc?.subscriptionType,
            permission: userDoc?.permission || "",
          });

          if (
            userDoc.subscriptionType.includes("enterprise_master") &&
            userDoc.enterpriseAccountId
          ) {
            await axiosWithToken(
              functionBaseUrl + "/api/verifyStaffEnt/" + user.uid,
              {
                method: "POST",
              }
            )
              .then(async (res) => {
                this.setState({
                  isStaffEnterprise: res.data.isStaffEnterprise,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }

          await axiosWithToken(functionBaseUrl + "/api/v1/mobileAppSettings", {
            method: "GET",
          })
            .then(async (res) => {
              if (
                res.data.deviceLimitExceptionList.includes(this.state.email) ||
                this.state.email.includes("sportsbox.ai") ||
                this.state.exceptionList.includes(this.state.email)
              ) {
                this.setState({ remoteSessionCheck: true });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          if (!userDoc.subscriptionType.includes("free")) {
            await axiosWithToken(
              functionBaseUrl + "/api/verifySub/" + user.uid,
              {
                method: "post",
              }
            )
              .then((response) => {
                this.setState({
                  subData: response.data.data,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      }
    });
  }

  render() {
    const { open, variant, onClose, className, classes, ...rest } = this.props;

    let pages = [
      {
        title: (
          <img src="/images/logos/SportsboxLogo.png" alt="logo" width={"75%"} />
        ),
        href: "/dashboard",
        //icon: <></>,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Swing Library",
        href: "/swing-library",
        icon: (
          <img
            src="/images/icons/SwingNavbarIcon.png"
            alt="Swing Library Icon"
          />
        ),
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Dashboard",
        href: "/dashboard",
        icon: <img src="/images/icons/dashboard.png" alt="logo" />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Account",
        href: "/account",
        icon: <img src="/images/icons/account.png" alt="logo" />,
        handleClick: () => {
          onClose();
        },
      },
      /*{
        title: "Credits",
        href: "/credits",
        icon: <CardGiftcardIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      {
        title: "Invoices",
        //href: "/invoices",
        href: "/enterprise/pay/students",
        icon: <ReceiptIcon />,
        handleClick: () => {
          onClose();
        },
      },
      /*{
        title: "Online 3DGolf Lesson",
        href: "/lesson",
        icon: <CreditCardIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      /*{
        title: "Upload",
        href: "/video-upload",
        icon: <BackupIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Sessions",
        href: "/session-details",
        icon: <TimelineIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      {
        title: "Remote Session",
        href: "/remote-session",
        icon: <DevicesIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Coaches",
        href: "/coaches",
        icon: <img src="/images/icons/coaches.png" alt="logo" />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Studio Coaches",
        href: "/studio-coaches",
        icon: <img src="/images/icons/coaches.png" alt="logo" />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Students",
        href: "/students",
        icon: <img src="/images/icons/students.png" alt="logo" />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Invites",
        href: "/invites",
        icon: <NotificationsIcon />,
        handleClick: () => {
          onClose();
        },
      },
      /*{
        title: "Learning",
        href: "/learning",
        icon: <SchoolIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      {
        title: "Search",
        href: "/search",
        icon: <SearchIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Summary",
        href: "/summary",
        icon: <BarChartIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Summary Graphs",
        href: "/summary-graphs",
        icon: <ShowChartIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Weekly User Reports",
        href: "/weekly-reports",
        icon: <AssignmentIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Tutorials",
        href: "/content-management",
        icon: <TocIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Practice Guides",
        href: "/practice-guides",
        icon: <TocIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "QA-Results",
        href: "/questionnaires",
        icon: <QuestionAnswerIcon />,
        handleClick: () => {
          onClose();
        },
      },
      /*{
        title: "Reports",
        href: "/reports",
        icon: <AssignmentIcon />,
        handleClick: () => {
          onClose();
        },
      },*/
      {
        title: "Enterprise",
        href: "/enterprise",
        icon: <BusinessIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Studio",
        href: "/studio",
        icon: <ComputerIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Bootcamp",
        href: "/bootcamp",
        icon: <Storefront />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "Unity",
        href: "/unity",
        icon: <PlayCircleFilledIcon />,
        handleClick: () => {
          onClose();
        },
      },
      {
        title: "LLM",
        href: "/llm",
        icon: <AssistantIcon />,
        handleClick: () => {
          onClose();
        },
      },
    ];

    let sidebarPages = () => {
      const {
        userRole,
        remoteSessionCheck,
        permission,
        subscriptionType,
        isStaffEnterprise,
      } = this.state;

      if (userRole === "admin") return pages;

      let tempPages = pages;

      const excludeTitles = (titles) => {
        tempPages = tempPages.filter((item) => !titles.includes(item.title));
      };

      const commonExclusions = [
        "Role Requests",
        "Reported Issues",
        "Summary",
        "Summary Graphs",
        "Coupons",
        "Enterprise",
        "Studio",
        "Bootcamp",
        "Reports",
        "Invoices",
        "Tutorials",
        "QA-Results",
        "Practice Guides",
        "Weekly User Reports",
        "Search",
        "LLM",
        ENVIRONMENT === "PRODUCTION" ? "Unity" : "",
      ];

      if (!remoteSessionCheck) {
        excludeTitles(["Remote Session"]);
      }

      /*if (permission === "") {
        excludeTitles(["Swing Library"]);
      }*/

      if (subscriptionType.includes("free")) {
        excludeTitles([...commonExclusions, "Students", "Credits", "Coaches", "Studio Coaches", "Swing Library"]);
      } else if (subscriptionType.includes("enterprise_coach")) {
        excludeTitles([...commonExclusions, "Invites", "Coaches", "Studio Coaches"]);
      } else if (subscriptionType.includes("enterprise_master")) {
        if (isStaffEnterprise) {
          excludeTitles([...commonExclusions, "Invites", "Coaches", "Studio Coaches"]);
        } else {
          excludeTitles([...commonExclusions, "Invites", "Studio Coaches"]);
        }
      } else if (subscriptionType.includes("student_lite")) {
        excludeTitles([...commonExclusions, "Students", "Credits", "Coaches", "Studio Coaches", "Swing Library"]);
      } else if (subscriptionType.includes("player_plus")) {
        excludeTitles([...commonExclusions, "Students", "Credits", "Coaches", "Studio Coaches"]);
      } else if (subscriptionType.includes("pro_lite")) {
        excludeTitles([...commonExclusions, "Coaches", "Studio Coaches", "Invites"]);
      } else if (subscriptionType.includes("studio_coach")) {
        excludeTitles([...commonExclusions, "Coaches", "Studio Coaches", "Invites"]);
      } else if (subscriptionType.includes("studio_master")) {
        excludeTitles([...commonExclusions, "Coaches", "Invites"]);
      } else {
        excludeTitles(commonExclusions);
      }

      return tempPages;
    };

    return (
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
        id="sidebarPage"
        onClick={Swal.close()}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          <SidebarNav className={classes.nav} pages={sidebarPages()} />
          <Profile history={this.props.history} />
        </div>
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(Sidebar);
